import styled from "styled-components";

export const Container = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;


    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    margin-bottom: 20px;
`

export const CheckMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${props => props.checked ? '#FFFFFF' : '#DFE4F7'};
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
      background-color: #FFFFFF;
    }

  `

export const CheckIcon = styled.span`
      width: 5px;
      height: 10px;
      border: solid #000000;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -3px;
  `