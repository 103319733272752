import styled, { css } from "styled-components";

const tabletBreakpoint = '768px'

export const Input = styled.input`
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7.38273px;
    width: calc(100% - 38px);
    border: none;
    margin-bottom: 12px;
    padding: 20px 19px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18.7924px;
    line-height: 24px;

    color: #000000;

    :hover, :focus{
        background: white;
    }


    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: black;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: black;
    }

    ${props => props.invalid && css`
        padding-right: 45px;
    `}


    @media only screen and (max-width: ${tabletBreakpoint}){
        font-size: 14px !important;
        line-height: 19px !important;
    }
`