import styled, { css } from "styled-components";
import pilotingBg from '../../assets/Dashboard/pilotingBg.png'
import footer from '../../assets/Dashboard/footer.svg'
import footerSmall from '../../assets/Dashboard/footer-small.svg'

const tabletBreakpoint = '768px'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

export const VideoBg = styled.video`
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
`

export const Logo = styled.img`
    position: absolute;
    left: 28px;
    top: 35px;
`

export const SupportedBy = styled.h3`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 124px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-top: 65px;
        font-size: 11px;
    }
`

export const CenterBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    max-width: 793px;
    width: 80%;
    left: calc(50% - (${props => props.width}px)/2);
    top: calc(50vh - (${props => props.height}px)/2);

    @media only screen and (max-width: ${tabletBreakpoint}){
        left: 30px;
        right: 30px;
        width: calc(100% - 60px);
    }
`

export const H1 = styled.h1`
    display: flex;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
`

export const Description = styled.p`
    display: flex;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    max-width: 862px;
    left: calc((100% - ${props => props.width}px)/2);
    top: calc(100vh - (${props => props.height}px + 40px));
    @media only screen and (max-width: ${tabletBreakpoint}){
        left: 30px;
        right: 30px;
    }
`

export const RegisterButton = styled.button`
    display: flex;
    width: 184px;
    background: #BFC9F2;
    border-radius: 5px;
    border: none;
    padding: 11px 28px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
`

export const NotBusiness = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #FFFFFF;

    :hover{
        cursor: pointer;
    }
`

export const CustomerRow = styled.div`
    position: absolute;
    right: 28px;
    top: 24px;
    display: flex;
    align-items: center;
`

export const CustomerButton = styled.button`
    background: #000000;
    border-radius: 3px;
    border: none;
    margin-left: 30px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    height: 30px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-top: 5px;
    }
`

export const SupportedRow = styled.div`
    display: flex;
    width: 564px;
    max-width: calc(100% - 60px);
    justify-content: flex-start;
    align-self: center;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`

export const SupportedBlock = styled.div`
    display: flex;
    animation: bannermove 20s linear infinite;
    @keyframes bannermove {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-50%, 0);
        }
    }
`

export const WhiteOverlay = styled.div`
    width: 136.25px;
    height: 140.73px;
    background: ${props => props.right ? 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)' : 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)'};
    transform: rotate(-90deg);
    position: absolute;
    z-index: 1;
    top: -55px;
    ${props => props.right && css`
        right: 0;
        transform: rotate(90deg);
    `}
`

export const Disclaimer = styled.h3`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 500;
    max-width: 564px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #9F9F9F;
    align-self: center;
    margin-top: 85px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-top: 45px;
        margin-left: 30px;
        margin-right: 30px;
    }
`

export const H2 = styled.h2`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin-top: 110px;
`

export const H2Desc = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    align-self: center;
    max-width: 512px;
    width: calc(100% - 60px);
`

export const MockScreen = styled.img`
    width: 100%;
    margin-top: 7em;
`

export const Step = styled.h3`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000000;
    @media only screen and (max-width: ${tabletBreakpoint}){
        font-size: 11px;
    }
`

export const StepDesc = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
`

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    @media only screen and (max-width: ${tabletBreakpoint}){
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1.5em;
        align-items: center;
    }
`

export const SubBlock = styled.div`
    display: flex;
    flex-direction: column;
`

export const StepIcon = styled.img`
    display: flex;
    margin-right: auto;
    height: 50px;
    margin-bottom: 0.4em;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-right: 20px;
        width: 35px;
    }
`

export const StepWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 120px);
    align-self: center;
    max-width: 1280px;
    margin-top: 86px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        flex-direction: column;
        width: calc(100% - 60px);
        margin-top: 55px;
        align-items: flex-start;
    }
`

export const TakeControlSection = styled.div`
    display: flex;
    flex-direction: ${props => props.customers ? 'row-reverse' : 'row'};
    background: ${props => props.customers ? '#968D7C' : '#028592'};
    width: 100%;
    align-items: center;
    @media only screen and (max-width: ${tabletBreakpoint}){
        flex-direction: column-reverse;
    }
`


export const ControlBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 340px;
    margin-left: ${props => props.customers ? 0 : '60px'};
    ${props => props.customers && css`
        margin-right: auto;
        margin-left: 30px;
    `}
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-left: 30px;
        margin-right: auto;
    }
`

export const TakeControlImg = styled.img`
    width: 60%;
    margin-top: 50px;
    margin-left: 30px;
    margin-bottom: 60px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-left: 0px;
        margin-top: 20px;
        width: 100%;
    }
`

export const TakeControlTitle = styled.h2`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
    color: #FFFFFF;
`

export const TakeControlDesc = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #FFFFFF;
    @media only screen and (max-width: ${tabletBreakpoint}){
        ${props => props.mobileMarginTop && css`
            margin-top: 2em
        `}
    }

`

export const Role = styled.h3`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 53px;
    
    @media only screen and (max-width: ${tabletBreakpoint}){
        font-size: 9px;
    }
`
export const TreinerImg = styled.img`
    width: 112px; 
    margin-bottom: 95px;
    align-self: center;
    @media only screen and (max-width: ${tabletBreakpoint}){
        display: none;
    }
`

export const PilotingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${pilotingBg});
    width: 100%;
    background-size: cover;
    background-position: center;
`

export const FooterSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${footer});
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-top: -160px;

    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-top: -40px;
        background-position: center;
    }

    @media only screen and (min-width: 1780px){
        margin-top: -250px;
    }
`

export const TouchSection = styled.div`
    display: flex;
    
    @media only screen and (max-width: ${tabletBreakpoint}){
        flex-direction: column;
    }
`
export const TouchImage = styled.img`
    width: 50%;
    max-height: 1783px;
    object-fit: cover;
    @media only screen and (max-width: ${tabletBreakpoint}){
        width: 100%;
        height: 390px;
    }
`
export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    background: #C0C8EE;
    width: calc(50% - 104px);
    padding-left: 52px;
    padding-right: 52px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const FormDesc = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 60px;
`

export const Acknowledgement = styled.p`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    max-width: 496px;
    margin-top: 60px;
    margin-bottom: 124px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-left: 30px;
        margin-right: 30px;
    }
`

export const LinksWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 36px;
    bottom: 23px;

    @media only screen and (max-width: ${tabletBreakpoint}){
        width: calc(100% - 60px);
        justify-content: space-evenly;
    }
`

export const FeedsWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 36px;
    bottom: 23px;

    @media only screen and (max-width: ${tabletBreakpoint}){
        width: 170px;
        justify-content: space-evenly;
        position: relative;
        margin-bottom: 73px;
        right: 0px;
    }
`

export const FeedIcon = styled.img`
    width: 35px;
    height: 35px;
    margin-right: 9px;
    :hover{
        cursor: pointer;
    }
`

export const Links = styled.a`
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 60px;
    text-decoration: none;

    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-right: 0px;
        font-size: 9px;
    }
`

export const ExcitedQ = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
    margin-top: 50px;
`

export const RegisterInterest = styled.button`
    width: 191px;
    height: 54px;
    background: ${props => props.disabled ? 'rgba(0, 0, 0, 0.3)' : '#000000'};
    border-radius: 7.5px;
    border: none;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    color: #FFFFFF;
    margin-top: 60px;
    margin-bottom: 200px;
`

export const ThankYou = styled.p`
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
    margin-top: 60px;
    margin-bottom: 200px;
`

export const FragmentedImage = styled.img`
    display: flex;
    align-self: center;
    margin-bottom: 195px;
    margin-top: 40px;
    max-width: 899px;
    @media only screen and (max-width: ${tabletBreakpoint}){
        margin-bottom: 136px;
        max-width: calc(100% - 20px);
    }
`

export const FragmentButton = styled.button`
    width: 128px;
    background: ${props => props.active ? '#888888' : 'rgba(217, 217, 217, 0.4)'};
    border-radius: 5px;
    border: none;
    display: flex;
    position: relative;
    align-items: center;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14.6154px;
    line-height: 21px;
    text-align: center;
    color:  ${props => props.active ? '#FFFFFF' : '#B3B3B3'};
    height: 40px;
    justify-content: center;
    align-self: center;
`