import React from "react";
import { Input } from "./styles";
import errorIcon from './assets/errorIcon.svg'

const InputField = ({ placeholder, email, url, value, onChange, invalid }) => {
    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            <Input invalid={invalid} type={email ? 'email' : url ? 'url' : 'text'} placeholder={placeholder} value={value} onChange={({ target }) => onChange(target.value)} />
            {invalid && <img id='error' src={errorIcon} style={{ position: 'absolute', right: '15px', top: '20px', width: '23px', height: '23px' }} />}
        </div>
    )
}

export default InputField