import React, { useEffect, useRef, useState } from "react";

import { Acknowledgement, Block, CenterBlock, Container, ControlBlock, CustomerButton, CustomerRow, Description, Disclaimer, ExcitedQ, FeedIcon, FeedsWrapper, FooterSection, FormDesc, FormSection, FragmentButton, FragmentedImage, H1, H2, H2Desc, Links, LinksWrapper, Logo, MockScreen, NotBusiness, PilotingSection, RegisterButton, RegisterInterest, Role, Step, StepDesc, StepIcon, StepWrapper, SubBlock, SupportedBlock, SupportedBy, SupportedRow, TakeControlDesc, TakeControlImg, TakeControlSection, TakeControlTitle, ThankYou, TouchImage, TouchSection, TreinerImg, VideoBg, WhiteOverlay } from "./styles";

import Dropdown from '../../Components/Dropdown'
import InputField from '../../Components/InputField'

import heroVideo from '../../assets/Dashboard/heroBackgroundv1.mp4'
import logoImg from '../../assets/Logo/oss-white.svg'

import antler from '../../assets/Dashboard/SupportedBy/antler.svg'
import blackbird from '../../assets/Dashboard/SupportedBy/blackbird.svg'
import cubes from '../../assets/Dashboard/SupportedBy/cubes.svg'
import generator from '../../assets/Dashboard/SupportedBy/generator.svg'
import startmate from '../../assets/Dashboard/SupportedBy/startmate.svg'
import treiner from '../../assets/Dashboard/SupportedBy/treiner.svg'
import startup from '../../assets/Dashboard/SupportedBy/startup.svg'
import silicon from '../../assets/Dashboard/SupportedBy/silicon.svg'
import monash from '../../assets/Dashboard/SupportedBy/monash.svg'
import startnow from '../../assets/Dashboard/SupportedBy/startnow.svg'
import dashboardMockScreen from '../../assets/Dashboard/dashboardMockScreen.png'
import integrate from '../../assets/Dashboard/integrate.svg'
import viewInsights from '../../assets/Dashboard/viewInsights.svg'
import requestData from '../../assets/Dashboard/requestData.svg'

import takeControl from '../../assets/Dashboard/takeControl.png'
import customers from '../../assets/Dashboard/customers.png'
import stayInTouch from '../../assets/Dashboard/stayInTouch.png'

import instagram from '../../assets/Dashboard/Socials/instagram.svg'
import facebook from '../../assets/Dashboard/Socials/facebook.svg'
import linkedin from '../../assets/Dashboard/Socials/linkedin.svg'
import twitter from '../../assets/Dashboard/Socials/twitter.svg'

import fragmentWithOss from '../../assets/Dashboard/Fragmented-withOss.gif'
import fragmentWithoutOss from '../../assets/Dashboard/Fragmented-withoutOss.gif'

import treinerDiagram from '../../assets/Dashboard/treiner.png'
import jamesTrainer from '../../assets/Dashboard/james-treiner.png'
import Checkbox from "../../Components/Checkbox";
import axios from "axios";

const Dashboard = props => {

    const [centerBlock, setCenterBlock] = useState({ width: '300px', height: '100px' })
    const [description, setDescription] = useState({ width: '300px', height: '100px' })

    const [h1Params, setH1Params] = useState({ width: '50%', height: '10%' })
    const [descParams, setDescParams] = useState({ width: '50%', height: '10%' })

    const descriptionEl = document.getElementById('description')
    const centerBlockEl = document.getElementById('center-block')

    const [wantTo, setWantTo] = useState('')
    const [amA, setAma] = useState('')
    const [industry, setIndustry] = useState('')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [email, setEmail] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [linkedIn, setLinkedIn] = useState('')
    const [excited, setExcited] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [fragmentWithOssView, setFragmentWithOssView] = useState(false)
    const [manualToggle, setManualToggle] = useState(false) //User toggled buttons, stop timers.
    const [timerButtonWidth, setTimerButtonWidth] = useState(0)

    const timerRef = useRef(null);


    const resize = () => {
        if (descriptionEl) {
            setDescription({ width: descriptionEl.getBoundingClientRect().width, height: descriptionEl.getBoundingClientRect().height })
            setCenterBlock({ width: centerBlockEl.getBoundingClientRect().width, height: centerBlockEl.getBoundingClientRect().height })
        }
    }

    useEffect(() => {
        if (descriptionEl) {
            setDescription({ width: descriptionEl.getBoundingClientRect().width, height: descriptionEl.getBoundingClientRect().height })
        }
    }, [descriptionEl])

    useEffect(() => {
        if (centerBlockEl) {
            setCenterBlock({ width: centerBlockEl.getBoundingClientRect().width, height: centerBlockEl.getBoundingClientRect().height })
        }
    }, [centerBlockEl])

    useEffect(() => {

        var observer = new IntersectionObserver(function (entries) {
            const elementWidth = document.getElementById('without-oss') && document.getElementById('without-oss').getBoundingClientRect().width
            if (elementWidth && entries[0].isIntersecting === true && elementWidth !== timerButtonWidth) {
                timerRef.current = setInterval(() => {
                    setTimerButtonWidth(t => t + 2)
                }, 100)
            }
        }, { threshold: [0] })

        observer.observe(document.querySelector("#fragment-buttons"))

        return () => clearInterval(timerRef.current)
    }, [])

    useEffect(() => {
        const elementWidth = document.getElementById('without-oss').getBoundingClientRect().width
        if (timerButtonWidth >= elementWidth) {
            setTimerButtonWidth(elementWidth)
            !manualToggle && setFragmentWithOssView(true)
            clearInterval(timerRef.current)
        }
    }, [timerButtonWidth])


    useEffect(() => {
        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
    }, [])

    // const supportedImagesContainer = document.getElementById('supported-images')

    // useEffect(() => {
    //     if (supportedImagesContainer) {
    //         setInterval(() => {
    //             if (supportedImagesContainer.scrollLeft !== '564px') {
    //                 supportedImagesContainer.scrollTo(supportedImagesContainer.scrollLeft + 1, 0);
    //             } else {
    //                 supportedImagesContainer.scrollTo(supportedImagesContainer.scrollLeft - 564, 0);
    //             }
    //         }, 15);
    //     }
    // }, [supportedImagesContainer])

    useEffect(() => {
        setH1Params({ width: centerBlock.width, height: centerBlock.height + 130 })
    }, [centerBlock])

    useEffect(() => {
        setDescParams({ width: description.width, height: description.height })
    }, [description])

    const supportedImages = [startup, silicon, monash, startnow, startmate, blackbird, generator, treiner, cubes, antler]

    const supportedImageMultiple = Array.from(Array(2).keys()).map(i => supportedImages).flat()


    const steps = [
        { title: 'Integrate', desc: 'Integrate Oss into your tech stack, leveraging your existing customer data. Start using the Oss platform to learn about your customers and demonstrate a commitment to trust and privacy.', icon: integrate },
        { title: 'Request data', desc: 'Send a data point request to your customers for more contextual data, ensuring data compliance. Customers retain control over their data points via the opt-in/out transparency integration.', icon: requestData },
        { title: 'View insights', desc: 'Obtain cleaned, aggregated and privacy first data about your customers.', icon: viewInsights },
    ]

    const socials = [
        { icon: instagram, link: 'https://www.instagram.com/ossappau/' },
        { icon: facebook, link: 'https://www.facebook.com/OssAppAU/' },
        { icon: linkedin, link: 'https://au.linkedin.com/company/oss-app' },
        { icon: twitter, link: 'https://mobile.twitter.com/app_oss' }
    ]

    const footerLinks = [
        { name: '©2022 Oss', link: '' },
        { name: 'Privacy Policy', link: 'https://ossapp.com.au/Oss-Privacy-Policy.pdf' },
        { name: 'Terms and Conditions', link: '' },
    ]


    useEffect(() => {
        if (error) {
            document.getElementById('error') && document.getElementById('error').scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [error])

    const handleFormSubmit = () => {
        setError('')
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setError('email')
            return
        }

        setLoading(true)

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LcACswhAAAAANB4i_Pdj1TVYxCOCxDaXGjVIhIM', { action: 'submit' }).then(token => {
                axios.post('https://australia-southeast1-oss-server-87726.cloudfunctions.net/collectBusinessInterests', {
                    wantTo,
                    amA,
                    industry,
                    firstName,
                    lastName,
                    email,
                    businessName,
                    linkedIn,
                    excited,
                    token
                }).then(resp => {
                    console.log(resp.data)
                    setLoading(false)
                    setSubmitted(true)
                }).catch(e => {
                    console.log(e)
                    setLoading(false)
                    setSubmitted(true)
                })
            })
        });
    }

    const checkIfFieldsFilled = () => !email || !firstName || !lastName || !amA || !industry || !wantTo || !businessName || excited.length === 0

    const toggleCheckbox = (v, name) => v ? setExcited([...excited, name]) : setExcited(excited.filter(e => e !== name))

    return (<Container>
        <VideoBg src={heroVideo} autoPlay muted loop playsInline />
        <Logo src={logoImg} />
        <CustomerRow>
            <NotBusiness onClick={() => document.getElementById('footer').scrollIntoView({ block: 'start', behavior: 'smooth' })}>Contact</NotBusiness>
            <CustomerButton onClick={() => window.open('https://ossapp.com.au/', '_blank')}>Oss for Customers</CustomerButton>
        </CustomerRow>
        <CenterBlock id='center-block' width={h1Params.width} height={h1Params.height}>
            <H1>Discover more about your customers while respecting their privacy </H1>
            <RegisterButton onClick={() => document.getElementById('register_form').scrollIntoView({ block: 'start', behavior: 'smooth' })}>Register interest</RegisterButton>
        </CenterBlock>
        <Description id='description' width={descParams.width} height={descParams.height}> Unify and access ‘hard to get’ data to harness powerful insights via a transparency layer that builds trust between you and your customers. </Description>
        <SupportedBy>SUPPORTED BY</SupportedBy>
        <SupportedRow id='supported-images'>
            <WhiteOverlay />
            <SupportedBlock>
                {supportedImageMultiple.map((icon, index) => <img src={icon} key={index} style={{ marginRight: '40px' }} />)}
            </SupportedBlock>
            <WhiteOverlay right />
        </SupportedRow>
        <Disclaimer>Product images and mockups are works in progress and may not be indicative of the final product</Disclaimer>
        <H2>Get the whole picture</H2>
        <H2Desc>A unified view of your customer’s journey — both inside and outside your product. Request and work with more data points to create richer insights and segments, as well as more personalised customer experiences.</H2Desc>
        <MockScreen src={dashboardMockScreen} />

        <StepWrapper>
            {
                steps.map(({ title, desc, icon }) =>
                    <Block key={title}>
                        <StepIcon src={icon} />
                        <SubBlock>
                            <Step>{title}</Step>
                            <StepDesc>{desc}</StepDesc>
                        </SubBlock>
                    </Block>
                )
            }
        </StepWrapper>

        <H2>Data you never thought you could get</H2>
        <H2Desc>Customer data is spread across different industries and siloed within big tech — but still exists with your customers. Opt in to a transparent approach to data collection.</H2Desc>
        <div id='fragment-buttons' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FragmentButton onClick={() => { setManualToggle(true); setFragmentWithOssView(false) }} active={!fragmentWithOssView} id='without-oss'>
                <label style={{ zIndex: 1 }}>without Oss</label>
                {!fragmentWithOssView && <div style={{ position: 'absolute', left: 0, width: timerButtonWidth, top: 0, bottom: 0, background: '#028592', borderRadius: '7px', zIndex: 0 }} />}
            </FragmentButton>
            <FragmentButton onClick={() => { setManualToggle(true); setFragmentWithOssView(true) }} active={fragmentWithOssView} id='with-oss'>
                <label style={{ zIndex: 1 }}>with Oss</label>
                {fragmentWithOssView && <div style={{ position: 'absolute', left: 0, width: timerButtonWidth, top: 0, bottom: 0, background: '#028592', borderRadius: '7px', zIndex: 0 }} />}
            </FragmentButton>
        </div>
        <FragmentedImage src={fragmentWithOssView ? fragmentWithOss : fragmentWithoutOss} />

        <TakeControlSection>
            <TakeControlImg src={takeControl} />
            <ControlBlock>
                <TakeControlTitle>Take control of your marketing </TakeControlTitle>
                <TakeControlDesc mobileMarginTop>Use the Oss platform to better understand your customers, create look-alikes — or use your custom datasets for efficient targeted advertsing.</TakeControlDesc>
            </ControlBlock>
        </TakeControlSection>

        <TakeControlSection customers>
            <TakeControlImg src={customers} />
            <ControlBlock customers>
                <TakeControlTitle>Your customers will own their data and appreciate you </TakeControlTitle>
                <TakeControlDesc mobileMarginTop>Consumers are demanding more transparency from businesses and more control over their data. Oss de-identifies customer data and provides a transparency layer so you can focus on learning more about your customers while respecting their privacy. </TakeControlDesc>
            </ControlBlock>
        </TakeControlSection>


        <SupportedBy>Pilot in progress</SupportedBy>
        <H2 style={{ marginTop: '28px' }}>Creating player/coach data ownership on soccer coaching platform Treiner</H2>
        <img src={treinerDiagram} style={{ width: '100%', alignSelf: 'center', maxWidth: '852px', marginTop: '70px', marginBottom: '75px' }} />
        <img src={jamesTrainer} style={{ width: '58px', height: '58px', alignSelf: 'center', marginBottom: '23px' }} />
        <H2Desc>Oss enables us to bring much-needed data ownership to the sports tech industry. With our sports and training customer data unified, we can provide better product experiences and personalised messaging. And the best thing — we’re doing it while respecting player and coach privacy.</H2Desc>
        <Role>James Muir, CEO</Role>
        <TreinerImg src={treiner} />

        <PilotingSection>
            <H1 style={{ marginTop: '160px' }}>We’re still piloting! </H1>
            <H2Desc style={{ color: 'white' }}>Our mission is to improve the power dynamics of the data industry and empower people with their data. We’re working away at building the platform and still have a few kinks to iron out — so we’re running pilots until mid-2023 with limited spots.</H2Desc>
            <H2Desc style={{ color: 'white', marginBottom: '160px' }}>Sign up below to register your interest!</H2Desc>
        </PilotingSection>

        <TouchSection>
            <TouchImage src={stayInTouch} />

            <FormSection id='register_form'>

                <H1 style={{ marginTop: '120px', color: '#000000' }}>Stay in touch</H1>
                <FormDesc>Register to follow our updates or become an early pilot partner to discover more about your customers while respecting their privacy.</FormDesc>


                <Dropdown
                    label="I want to..."
                    options={[
                        { label: 'Be part of the pilot', value: 'Be part of the pilot' },
                        { label: 'Only receive general news and updates', value: 'Only receive general news and updates' },
                    ]}
                    value={wantTo}
                    onChange={setWantTo}
                />

                <Dropdown
                    label="I am a..."
                    options={[
                        { label: 'Marketer', value: 'Marketer' },
                        { label: 'Product Manager', value: 'Product Manager' },
                        { label: 'Market Researcher', value: 'Market Researcher' },
                        { label: 'UI/UX Designer', value: 'UI/UX Designer' },
                        { label: 'CEO/Founder', value: 'CEO/Founder' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    value={amA}
                    onChange={setAma}
                />

                <Dropdown
                    label="The industry I work in is..."
                    options={[
                        { label: 'Retail', value: 'Retail' },
                        { label: 'eCommerce', value: 'eCommerce' },
                        { label: 'Sports Tech', value: 'Sports Tech' },
                        { label: 'Medtech', value: 'Medtech' },
                        { label: 'Hospitality', value: 'Hospitality' },
                        { label: 'Marketplaces', value: 'Marketplaces' },
                        { label: 'B2B', value: 'B2B' },
                        { label: 'Finance', value: 'Finance' },
                        { label: 'Mobile', value: 'Mobile' },
                        { label: 'Marketing Tech / Agency', value: 'Marketing Tech / Agency' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    value={industry}
                    onChange={setIndustry}
                />
                <div style={{ display: 'flex', marginTop: '40px', justifyContent: 'space-between' }}>
                    <div style={{ width: 'calc(50% - 10px)' }}>
                        <InputField placeholder="First name" value={firstName} onChange={setFirstName} />
                    </div>
                    <div style={{ width: 'calc(50% - 10px)' }}>
                        <InputField placeholder="Last name" value={lastName} onChange={setLastName} />
                    </div>
                </div>

                <InputField placeholder="Email" email value={email} invalid={error === 'email'} onChange={setEmail} />
                <InputField placeholder="Business name" value={businessName} onChange={setBusinessName} />
                <InputField placeholder="LinkedIn (URL please)" url value={linkedIn} onChange={setLinkedIn} />

                <ExcitedQ>What are you most excited about?</ExcitedQ>
                <Checkbox label='Unifying my data' checked={excited.includes('unify')} setChecked={v => toggleCheckbox(v, 'unify')} />
                <Checkbox label='Single view of my customers' checked={excited.includes('singleView')} setChecked={v => toggleCheckbox(v, 'singleView')} />
                <Checkbox label='Requesting and working with more data points' checked={excited.includes('requestingDataPoints')} setChecked={v => toggleCheckbox(v, 'requestingDataPoints')} />
                <Checkbox label='Creating new insights, segments and lookalikes' checked={excited.includes('insights')} setChecked={v => toggleCheckbox(v, 'insights')} />
                <Checkbox label='Using custom datasets for targeted advertising' checked={excited.includes('customDatasets')} setChecked={v => toggleCheckbox(v, 'customDatasets')} />
                <Checkbox label='Improving my brand image and taking a stance on privacy' checked={excited.includes('privacy')} setChecked={v => toggleCheckbox(v, 'privacy')} />
                <Checkbox label='Oss’ mission and vision of data ownership' checked={excited.includes('ossMission')} setChecked={v => toggleCheckbox(v, 'ossMission')} />
                {!submitted ?
                    <RegisterInterest disabled={checkIfFieldsFilled() || loading} onClick={handleFormSubmit}>Register interest</RegisterInterest>
                    :
                    <ThankYou>Thank you, we’ll be in touch soon!</ThankYou>
                }

            </FormSection>
        </TouchSection>
        <FooterSection id='footer'>
            <img src={logoImg} style={{ width: '123px', marginTop: '100px' }} />

            <Acknowledgement>
                Oss acknowledges the Boon wurrung and Woi wurrung language groups of eastern Kulin on whose unceded lands, waterways and skies we build and carry on our business.
                As we begin to connect people with their data — we respectfully acknowledge and recognise Aboriginal and Torres Strait Islander Peoples' continuing connection to identity, culture and Country as well as their ancestors past and present.
            </Acknowledgement>

            <FeedsWrapper>
                {socials.map(({ icon, link }) => <FeedIcon key={link} src={icon} onClick={() => window.open(link, '_blank')}></FeedIcon>)}
            </FeedsWrapper>


            <LinksWrapper>
                {footerLinks.map(({ name, link }) => <Links key={name} href={link}>{name}</Links>)}
            </LinksWrapper>
        </FooterSection>

    </Container>)

}

export default Dashboard