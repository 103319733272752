import styled from "styled-components";
import downArrow from './assets/downArrow.svg'

export const DropdownContainer = styled.select`
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7.4px;
    width: calc(100% - 0px);
    padding: 20px 19px;
    border: none;
    appearance: none;
    background-image: url(${downArrow});
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 25px;
    margin-bottom: 11px;



    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18.7924px;
    line-height: 24px;

    color: #000000;
    :hover, :focus{
        background: white;
    }
`