import React from "react";
import { DropdownContainer } from "./styles";

const Dropdown = ({ label, value, options, onChange }) => {
    return (
        <label>
            {/* {label} */}
            <DropdownContainer value={value} onChange={({ target }) => onChange(target.value)}>
                <option value="" disabled defaultValue>{label}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </DropdownContainer>
        </label>
    );
};

export default Dropdown