import React from "react";
import { CheckIcon, CheckMark, Container } from "./styles";

const Checkbox = ({ label, checked, setChecked }) => {
    return (
        <Container>{label}
            <input type="checkbox" />
            <CheckMark checked={checked} onClick={() => setChecked(!checked)}>
                {checked && <CheckIcon />}
            </CheckMark>
        </Container>
    );
};

export default Checkbox